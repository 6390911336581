@import '../../components/theme/ogle-colors.scss';
@import '../../components/theme/ogle-inputs.scss';

.ogle {
    .form-row {
        padding-bottom: 1.5rem;
    
        &:last-of-type {
            padding-bottom: 0;
        }
    }
    
    .form-row__label--required {
        position: relative;
    
        &:after {
            content: ' *';
            display: inline-block;
            color: map-get($colors, error-600);
        }
    }
    
    .form-row__label {
        padding-bottom: 0.375rem;
    }
    
    .form-row__label--checkout {
        order: 2;
        padding-bottom: 0;
        padding-left: 0.75rem;
    }
    
    .form-row__input-container {
        position: relative;
    }
    
    .form-row__input {
        padding: 0.5625rem 2.375rem 0.5625rem 0.875rem;
        border: 1px solid map-get($colors, gray-300);
        border-radius: 8px;
        color: map-get($colors, gray-900);
        width: 100%;
        line-height: 1.5rem;
    
        &:focus, &:active {
            border-color: map-get($colors, primary-300);
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px map-get($colors, primary-50);
            outline: none;
        }
    
        &::placeholder {
            color: map-get($colors, gray-500);
        }
    }
    
    textarea.form-row__input {
        resize: none;
    }

    .form-row__input--select {
        appearance: none;
    }
    
    .form-row__input--error {
        border-color: map-get($colors, error-300);
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px map-get($colors, error-50);
    }
    
    .form-row__input-icon {
        position: absolute;
        height: 0.875rem;
        right: 15px;
        top: 15px;
    }

    .form-row__input-icon-select {
        position: absolute;
        top: 0.75rem;
        right: 0.875rem;
    }
    
    .form-row__input--readonly {
        background-color: map-get($colors, gray-50);
    }
    
    @media screen and (min-width: 768px){
    
        .form-row__input-container {
            width: 100%;
        }
    }
}