@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './ogle-colors.scss';

.ogle {
	font-family: 'Inter', sans-serif;

	a {text-decoration: none;}
	.text-decoration-underline {text-decoration: underline;}
	ol, ul {list-style: none;}

	.block {padding: 0 1.5rem;}
	.block--with-bg {margin: 0 -1.5rem; padding: 0 3rem;}

	.cursor-pointer {cursor: pointer;}
	.pointer-events-none {pointer-events: none;}

	@media only screen and (min-width: 768px) {
		.view {max-width: 80rem; margin: 0 auto;}
		.block {padding: 0 2rem;}
		.block--with-bg {margin: 0 -2rem; padding: 0 4rem;} 
	}

	//
	// Typography
	//
	.light {font-weight: 300;}
	//.regular {font-weight: 400;}
	.medium {font-weight: 500;}
	.semibold {font-weight: 600;}
	.bold {font-weight: 700;}
	.display-2xl {font-size: 4.5rem; line-height: 5.636rem;}
	.display-xl {font-size: 3.75rem; line-height: 4.625rem;}
	.display-lg {font-size: 3rem; line-height: 3.75rem;}
	.display-md {font-size: 2.25rem; line-height: 2.75rem;}
	.display-sm {font-size: 1.875rem; line-height: 2.375rem;}
	.display-xs {font-size: 1.5rem; line-height: 2rem;}
	.text-header {font-size: 1.875rem; line-height: 2.375rem}
	.text-2xl {font-size: 1.5rem; line-height: 2rem}
	.text-xl {font-size: 1.25rem; line-height: 1.875rem;}
	.text-lg {font-size: 1.125rem; line-height: 1.75rem;}
	.text-md {font-size: 1rem; line-height: 1.5rem;}
	.text-sm {font-size: 0.875rem; line-height: 1.25rem;}
	.text-xs {font-size: 0.75rem; line-height: 1.125rem;}
	@media only screen and (min-width: 768px) {
		.mw768-display-2xl {font-size: 4.5rem; line-height: 5.636rem;}
		.mw768-display-xl {font-size: 3.75rem; line-height: 4.625rem;}
		.mw768-display-lg {font-size: 3rem; line-height: 3.75rem;}
		.mw768-display-md {font-size: 2.25rem; line-height: 2.75rem;}
		.mw768-display-sm {font-size: 1.875rem; line-height: 2.375rem;}
		.mw768-display-xs {font-size: 1.5rem; line-height: 2rem;}
		.mw768-text-2xl {font-size: 1.5rem; line-height: 2rem}
		.mw768-text-xl {font-size: 1.25rem; line-height: 1.875rem;}
		.mw768-text-lg {font-size: 1.125rem; line-height: 1.75rem;}
		.mw768-text-md {font-size: 1rem; line-height: 1.5rem;}
		.mw768-text-sm {font-size: 0.875rem; line-height: 1.25rem;}
		.mw768-text-xs {font-size: 0.75rem; line-height: 1.125rem;}
		.mw768-light {font-weight: 300;}
		.mw768-normal {font-weight: 400;}
		.mw768-medium {font-weight: 500;}
		.mw768-semibold {font-weight: 600;}
		.mw768-bold {font-weight: 700;}
	}

	@mixin color-modifiers($map, $attribute, $stroke: false) {
		@each $key, $value in $map {
			&-#{$key} {
				#{$attribute}: $value;

				@if $stroke {
					path {
						stroke: $value;
					}
				}
			}
		}
	}

	@mixin color-modifiers-hover($map, $attribute, $stroke: false) {
		@each $key, $value in $map {
			.hover\:#{$attribute}-#{$key}:hover {
				#{$attribute}: $value;

				@if $stroke {
					path {
						stroke: $value;
					}
				}
			}
		}
	}

	.outline-color {
		@include color-modifiers($colors, 'outline-color');
	}
	.border-color {
		@include color-modifiers($colors, 'border-color');
	}
	.border-left-color {
		@include color-modifiers($colors, 'border-left-color');
	}
	.border-right-color {
		@include color-modifiers($colors, 'border-right-color');
	}
	.border-top-color {
		@include color-modifiers($colors, 'border-top-color');
	}
	.border-bottom-color {
		@include color-modifiers($colors, 'border-bottom-color');
	}
	@media only screen and (min-width: 768px) {
		.mw768 {
			&-border-color {
				@include color-modifiers($colors, 'border-color');
			}
		}
	}

	//.color-gray-25 {
	//	color: #FCFCFD;
	//
	//	path {
	//		stroke: #FCFCFD;
	//	}
	//}
	.color {
		@include color-modifiers($colors, 'color', true);
	}
	//.background-color-gray-25 {
	//	background-color: #FCFCFD;
	//}
	.background-color {
		@include color-modifiers($colors, 'background-color');
	}
	@include color-modifiers-hover($colors, 'background-color');
	
	@media only screen and (min-width: 768px) {
		.mw768 {
			&-background-color {
				@include color-modifiers($colors, 'background-color');
			}
		}
	}

	//
	// Gradients
	//
	.gradient-grey-900-600-45deg {
		background: linear-gradient(45deg, #101828 0%, #475467 100%);
	}
	.gradient-grey-800-700-26dot5deg {
		background: linear-gradient(26.57deg, #1D2939 8.33%, #344054 91.67%);
	}

	.letter-spacing-minus-0dot04rem {
		letter-spacing: -0.04rem;
	}

	.card-shadow-small {
		box-shadow: 0px 6px 12px -2px rgba(50, 50, 93, 0.25), 0px 3px 7px -3px rgba(0, 0, 0, 0.3);
	}

	.shadow-large-light {
		box-shadow: 0px 16px 32px -4px rgba(16, 24, 40, 0.05);
	}

	.shadow-xl {
		box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10);
	}

	.shadow-lg {
		box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.04);
	}

	.shadow-md {
		box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.04), 0px 4px 8px -2px rgba(16, 24, 40, 0.08);
	}

	.shadow-xs {
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.10);
	}
	.divider {
		border-bottom-style: solid;
		border-bottom-width: 1px;
		height: 1px;
	}

	//
	// Spacing
	//
	$spacings: (
		'0': 0, '0dot0625rem': 0.0625rem, '0dot125rem': 0.125rem, '0dot25rem': 0.25rem, '0dot375rem': 0.375rem, '0dot5rem': 0.5rem, '0dot5625rem' : 0.5625rem, '0dot625rem': 0.625rem, '0dot75rem': 0.75rem, '0dot875rem': 0.875rem, '1rem': 1rem, '1dot25rem': 1.25rem, '1dot5rem': 1.5rem, '1dot75rem': 1.75rem, '2rem': 2rem, '2dot25rem': 2.25rem, '2dot375rem': 2.375rem, '2dot5rem': 2.5rem, '2dot75rem': 2.75rem, '3rem': 3rem, '3dot25rem': 3.25rem, '3dot5rem': 3.5rem,  '4rem': 4rem, '4dot25rem': 4.25rem, '4dot125rem': 4.125rem, '4dot5rem': 4.5rem, '5rem': 5rem, '6rem': 6rem, '6dot125rem': 6.125rem, '7dot5rem': 7.5rem, '8rem': 8rem, '10rem': 10rem, '11rem': 11rem, '11dot875rem': 11.875rem, '12rem': 12rem, '13dot375rem': 13.375rem,  '14rem': 14rem, '15rem': 15rem, '16rem': 16rem, '19dot5rem': 19.5rem, '22rem' : 22rem, '23rem' : 23rem, '37rem' : 37rem, '63rem' : 63rem
	);

	@mixin spacing-modifiers($map, $type) {
		@each $key, $value in $map {
			.#{$type}-#{$key} {
				#{$type}: $value;
			}
			.first-child\:#{$type}-#{$key}:first-child {
				#{$type}: $value;
			}
		}
	}
	@mixin spacing-modifiers-ex($map, $type) {
		@each $key, $value in $map {
			&-#{$type}-#{$key} {
				#{$type}: $value;
			}
		}
	}

	@mixin spacing-modifiers-ex0($map, $type, $type1) {
		@each $key, $value in $map {
			.#{$type}-#{$type1}-#{$key} {
				-webkit-#{$type}: unquote($type1+unquote('(#{$value})'));
				#{$type}: unquote($type1+unquote('(#{$value})'));
			}
		}
	}

	// .backdrop-filter-blur-0dot25rem {
	// 	-webkit-backdrop-filter: blur(0.25rem);
	// 	backdrop-filter: blur(0.25rem);
	// }
	@include spacing-modifiers-ex0($spacings, 'backdrop-filter', 'blur');

	//.min-height-0dot25rem {
	//	min-height: 0.25rem;
	//}
	// .first-child\:min-height-0dot25rem:first-child {
	// 	min-height: 0.25rem;
	// }
	@include spacing-modifiers($spacings, 'min-height');
	@include spacing-modifiers($spacings, 'max-height');
	@include spacing-modifiers($spacings, 'min-width');
	@include spacing-modifiers($spacings, 'max-width');
	@include spacing-modifiers($spacings, 'width');
	@include spacing-modifiers($spacings, 'height');
	@include spacing-modifiers($spacings, 'border-radius');
	@include spacing-modifiers($spacings, 'border-top-left-radius');
	@include spacing-modifiers($spacings, 'border-top-right-radius');
	@include spacing-modifiers($spacings, 'border-bottom-left-radius');
	@include spacing-modifiers($spacings, 'border-bottom-right-radius');
	@include spacing-modifiers($spacings, 'margin');
	@include spacing-modifiers($spacings, 'margin-left');
	@include spacing-modifiers($spacings, 'margin-right');
	@include spacing-modifiers($spacings, 'margin-top');
	@include spacing-modifiers($spacings, 'margin-bottom');
	@include spacing-modifiers($spacings, 'padding');
	@include spacing-modifiers($spacings, 'padding-left');
	@include spacing-modifiers($spacings, 'padding-right');
	@include spacing-modifiers($spacings, 'padding-top');
	@include spacing-modifiers($spacings, 'padding-bottom');
	@include spacing-modifiers($spacings, 'top');
	@include spacing-modifiers($spacings, 'bottom');
	@include spacing-modifiers($spacings, 'left');
	@include spacing-modifiers($spacings, 'right');
	@include spacing-modifiers($spacings, 'column-gap');
	@include spacing-modifiers($spacings, 'row-gap');

	@media only screen and (min-width: 768px) {
		.mw768 {
			@include spacing-modifiers-ex($spacings, 'min-height');
			@include spacing-modifiers-ex($spacings, 'max-height');
			@include spacing-modifiers-ex($spacings, 'min-width');
			@include spacing-modifiers-ex($spacings, 'max-width');
			@include spacing-modifiers-ex($spacings, 'width');
			@include spacing-modifiers-ex($spacings, 'height');
			@include spacing-modifiers-ex($spacings, 'border-radius');
			@include spacing-modifiers-ex($spacings, 'border-top-left-radius');
			@include spacing-modifiers-ex($spacings, 'border-top-right-radius');
			@include spacing-modifiers-ex($spacings, 'border-bottom-left-radius');
			@include spacing-modifiers-ex($spacings, 'border-bottom-right-radius');
			@include spacing-modifiers-ex($spacings, 'margin');
			@include spacing-modifiers-ex($spacings, 'margin-left');
			@include spacing-modifiers-ex($spacings, 'margin-right');
			@include spacing-modifiers-ex($spacings, 'margin-top');
			@include spacing-modifiers-ex($spacings, 'margin-bottom');
			@include spacing-modifiers-ex($spacings, 'padding');
			@include spacing-modifiers-ex($spacings, 'padding-left');
			@include spacing-modifiers-ex($spacings, 'padding-right');
			@include spacing-modifiers-ex($spacings, 'padding-top');
			@include spacing-modifiers-ex($spacings, 'padding-bottom');
			@include spacing-modifiers-ex($spacings, 'top');
			@include spacing-modifiers-ex($spacings, 'bottom');
			@include spacing-modifiers-ex($spacings, 'left');
			@include spacing-modifiers-ex($spacings, 'right');
			@include spacing-modifiers-ex($spacings, 'column-gap');
			@include spacing-modifiers-ex($spacings, 'row-gap');
		}
	}

	@media only screen and (min-width: 1024px) {
		.mw1024 {
			@include spacing-modifiers-ex($spacings, 'margin-left');
			@include spacing-modifiers-ex($spacings, 'margin-right');
			@include spacing-modifiers-ex($spacings, 'margin-top');
			@include spacing-modifiers-ex($spacings, 'margin-bottom');
			@include spacing-modifiers-ex($spacings, 'margin');
			@include spacing-modifiers-ex($spacings, 'padding-left');
			@include spacing-modifiers-ex($spacings, 'padding-right');
			@include spacing-modifiers-ex($spacings, 'padding-top');
			@include spacing-modifiers-ex($spacings, 'padding-bottom');
			@include spacing-modifiers-ex($spacings, 'padding');
		}
	}

	//
	// Form
	//
	.form_beta {
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-text-fill-color: map-get($colors, gray-500) !important;
			color: map-get($colors, gray-500) !important;
			caret-color: map-get($colors, gray-500) !important;
			transition: background-color 5000s ease-in-out 0s;
		}
		input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none;}
		input[type=number] {-moz-appearance: textfield;}
		input:focus {outline: none;}
		.input {box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);}
		.input:focus-within {
			border-color: #84CAFF;
			box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #EFF8FF;
		}
		.input.errors, .input.errors:focus-within {
			border-color: #FDA29B;
			box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEF3F2;
		}

		input[type="search"]::-webkit-search-decoration,
		input[type="search"]::-webkit-search-cancel-button,
		input[type="search"]::-webkit-search-results-button,
		input[type="search"]::-webkit-search-results-decoration {
			display: none;
		}
	}

	//
	// Layout
	//
	// https://blog.logrocket.com/understanding-css-grid-by-building-your-own-grid/
}
