@import '../../components/theme/ogle-colors.scss';

.ogle {
    .pages {
        .checkout {
            .checkout-content {
                border-radius: 12px 12px 0px 0px;
                background-color: #ffffff;
            }
            
            .checkout-footer {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            
            .checkout-shell-header {
                position: relative;
                padding: 1rem 0;
            
                &:before {
                    background: linear-gradient(45deg, map-get($colors, primary-700) 0%, map-get($colors, primary-600)100%);
                    height: 104px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    display: block;
                    content: '';
                    z-index: -1;
                }
            }
            
            .checkout-shell-footer__block {
                border-top: 1px solid map-get($colors, gray-200);
            }
            
            .checkout-shell-footer__legal {
                flex-direction: column;
                align-items: center;
            }
            
            .checkout-content__shadow-block {
                box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
            }
            
            .block-width {
                margin-right: -1.5rem;
                margin-left: -1.5rem;
                padding-right: 1.5rem;
                padding-left: 1.5rem;
            }
            
            .checkout-content__message {
                padding: 1rem 1.5rem;
            }
            
            @media screen and (min-width: 768px) {
            
                .checkout-content {
                    max-width: 41rem;
                    margin: 0 auto;
                    width: 100%;
                }
            
                .checkout-footer {
                    align-items: center;
                    text-align: center;
                }
            
                .checkout-view {
                    width: 100%;
                    margin: 0 auto;
                    min-height: 300px;
                }
            
                .checkout-view--container {
                    max-width: 63rem;
                }
            
                .checkout-shell-header {
                    padding: 1.25rem 0 2.75rem 0;
                
                    &:before {
                        height: 300px;
                    }
                }
            
                .checkout-shell-footer__block {
                    border-bottom: 1px solid map-get($colors, gray-200);
                    border-top: none;
                }
            
                .checkout-shell-footer__legal {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }
            
                .block-width {
                    margin-right: -2rem;
                    margin-left: -2rem;
                    padding-right: 2rem;
                    padding-left: 2rem;
                }
            
                .checkout-content__message {
                    padding: 1.25rem 2rem;
                    border-radius: 0px 0px 12px 12px;
                }
            }
            
        }
    }
}