@import './ogle-colors.scss';

.ogle {
    .ogle-checkbox-input, .ogle-radio-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    
    
        &:checked + .ogle-checkbox {
            background-color: map-get($colors, primary-600);
            border: 1px solid map-get($colors, primary-50);
            
            svg {
                display: block;
            }
        }
    
        &:disabled + .ogle-checkbox {
            background-color: map-get($colors, gray-100);
        }
    
        &:checked + .ogle-radio {
            background-color: map-get($colors, primary-600);
            border: 1px solid white;
            
            &:before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                position: absolute;
                left: 5px;
                top: 5px;
                background-color: white;
                border-radius: 50%;
            }
            
        }
    }
    
    // div styled instead of input classname
    .ogle-checkbox {
        border-radius: 6px;
        width: 20px;
        min-width: 20px;
        height: 20px;
        display: block;
        border: 1px solid map-get($colors, gray-300);
        background-color: white;
        position: relative;
    
        svg {
            display: none;
            width: 14px;
            height: 14px;
            position: absolute;
            left: 2px;
            top: 2px;
        }
    }
    
    .ogle-radio {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: block;
        border: 1px solid map-get($colors, gray-300);
        position: relative;
    }

    .ogle-input {
        padding: 0.5625rem 2.375rem 0.5625rem 0.875rem;
        border: 1px solid map-get($colors, gray-300);
        border-radius: 8px;
        color: map-get($colors, gray-900);
        width: 100%;
        line-height: 1.5rem;
    
        &:focus, &:active {
            border-color: map-get($colors, primary-300);
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px map-get($colors, primary-50);
            outline: none;
        }
    
        &::placeholder {
            color: map-get($colors, gray-500);
        }
    }

    .ogle-input--error {
        border-color: map-get($colors, error-300);
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px map-get($colors, error-50);
    }
}