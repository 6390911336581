@import '../../components/theme/ogle-colors.scss';

.ogle {
    .pages {
        .warp {
            .e {
                background: black;
                min-height: 100vh;

                .block {
                    margin: 0;
                }
              
                .max-width-50rem {
                      max-width: 50rem;
                }
                
                hr {
                    border: none;
                    color: map-get($colors, primary-500);
                    background-color: map-get($colors, primary-500);
                    height: 1px;
                }
                
                .e__accent {
                    font-size: 1.6rem;
                }
                
                @media only screen and (min-width: 768px) {
                    .e__accent {
                        font-size: 2.4rem;
                    }
                }
                
                .e__title {
                    font-size: 2rem;
                    text-align: center;
                }
                .marked {
                    color: white;
                }
                .marked img {
                    max-height: 100%;
                }
                .marked p {
                    padding-bottom: 1rem;
                }
                .marked hr {
                    margin-bottom: 1rem;
                }
                .marked strong {
                    font-weight: bold;
                }
                
                .view .marked a {
                    color: map-get($colors, primary-500);
                }
              
                .gap-0dot5rem-1rem {
                    gap: 0.5rem 1rem;
                }

                .flex-order-1 {
                    order: 1;
                }
                .flex-order-2 {
                    order: 2;
                }
                .width-7rem {
                    width: 7rem;
                }
                .box-shadow-xs {
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                }

                .min-height-2dot625rem {
                    min-height: 2.625rem;
                }

                .buy-btn-animation--forward {
                    transition: border 50ms ease-out 50ms;
                    animation-name: scaleBtn;
                    animation-duration: 50ms;
                    animation-timing-function: ease-in-out;
                    animation-play-state: paused;
                }

                .buy-btn-animation--backwards {
                    transition: border 100ms ease-out 100ms;
                }

                .buy-btn-animation--play {
                    animation-play-state: running;
                }

                .add-to-cart--visible {
                    opacity: 1;
                    visibility: visible;
                    width: auto;
                    transition: opacity 100ms ease-out 100ms, visibility 100ms ease-out 100ms;
                }

                .add-to-cart--hidden {
                    opacity: 0;
                    width: 0;
                    visibility: hidden;
                    transition: all 50ms ease-out 0;
                    position: absolute;
                }

                .decrement-endpoint--visible {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    position: absolute;
                    right: calc(100% - 2rem);
                    transition: opacity 100ms ease-in-out 50ms, visibility 100ms ease-in-out 50ms, transform 100ms ease-in-out 50ms;
                }

                .decrement-endpoint--hidden {
                    opacity: 0;
                    visibility: hidden;
                    transform: translateX(50%);
                    right: 50%;
                    position: absolute;
                    transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out, transform 100ms ease-in-out;
                    animation: 100ms ease-in-out hideRight;
                    pointer-events: none;
                }

                .increment-endpoint--visible {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    position: absolute;
                    left: calc(100% - 2rem);
                    transition: opacity 100ms ease-in-out 50ms, visibility 100ms ease-in-out 50ms, transform 100ms ease-in-out 50ms;
                    
                }

                .increment-endpoint--hidden {
                    opacity: 0;
                    visibility: hidden;
                    transform: translateX(-50%);
                    left: 50%;
                    position: absolute;
                    transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out;
                    animation: 100ms ease-in-out hideLeft;
                    pointer-events: none;
                }
                
                .endpoint-quantity--hidden {
                    opacity: 0;
                    visibility: hidden;
                    width: 0;
                    min-width: 0;
                    transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out;
                }

                .endpoint-quantity--visible {
                    opacity: 1;
                    visibility: visible;
                    width: auto;
                    min-width: 2rem;
                    padding: 0 0.25rem;
                    transition: opacity 50ms ease-out 150ms, visibility 50ms ease-out 150ms;
                }

                @keyframes scaleBtn {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(0.98);
                    }
                    100% {
                        transform: scale(0.98);
                    }
                }

                @keyframes hideLeft {
                    0% {
                        transform: translateX(0);
                        left: calc(100% - 2rem);
                    }
                    100% {
                        left: calc(100% - 2rem);
                        transform: translateX(0);
                    }
                }

                @keyframes hideRight {
                    0% {
                        transform: translateX(0);
                        right: calc(100% - 2rem);
                    }
                    100% {
                        right: calc(100% - 2rem);
                        transform: translateX(0);
                    }
                }
                
                @media only screen and (min-width: 768px) {
                    .mw768-height-2dot5rem {
                      height: 2.5rem;
                    }
              
                    .mw768-display-block {
                      display: block;
                    }
                    .mw768-flex-order-1 {
                        order: 1;
                    }
                    .mw768-flex-order-2 {
                        order: 2;
                    }
                }
            }
        }
    }
}