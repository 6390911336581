@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body:has(.pop-up-cart--visible) {
    overflow: hidden;
}
.ogle {
    .fcfc {
        background: black;
        color: #E4E7EC;
        min-height: 100vh;
        font-family: 'Inter', sans-serif;

        .block {
            margin: 0;
        }

        hr {
            border: none;
            color: #344054;
            background-color: #344054;
            height: 1px;
        }

        .marked {
            color: white;
        }
        .marked img {
            max-height: 100%;
        }
        .marked p {
            padding-bottom: 1rem;
        }
        .marked hr {
            margin-bottom: 1rem;
        }
        .marked strong {
            font-weight: bold;
        }

        .content a {
            color: #2E90FA;
        }

        .button {
            border-radius: 0.5rem;
            padding: 0.625rem 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.25rem;
            &.primary {
                color: white;
                border-color: #D0D5DD;
            }
        }
        .top-1dot25rem {
            top: 1.25rem;
        }
        
        .right-1dot25rem {
            right: 1.25rem;
        }
        
        .height-2rem {
            height: 2rem;
        }

        .padding-top-2rem {
            padding-top: 2rem;
        }
        .border-color-gray-700 {
            border-color: #344054;
        }
        .margin-left-0dot75rem {
            margin-left: 0.75rem;
        }
        .text-lg {
            font-size: 1.125rem;
            line-height: 1.75rem;
        }
        .medium {
            font-weight: 500;
        }
        .min-width-11rem {
            min-width: 11rem;
        }
        .display-none {
            display: none;
        }
        .position-sticky {
            position: sticky;
        }
        .btn--primary-gradient {
            &:disabled {
                background: #101323;
                color: #293056;
                border:none;

                path {
                    stroke: #293056;
                }
            }
        }
        .cart-footer {
            background-color: #fff;
            box-shadow: 0px -24px 48px -12px rgba(16, 24, 40, 0.05);
            border-radius: 12px 12px 0px 0px;
            padding: 1.25rem;
            width: 100vw;
        
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .cart-footer__details {
            opacity: 0;
            visibility: hidden;
            height: 0;
            transition: all 560ms cubic-bezier(0.77, 0, 0.18, 1);
            padding-left: 2.375rem;
        }
        
        .cart-footer__details--visible {
            opacity: 1;
            visibility: visible;
            height: 36px;
            padding-bottom: 12px;
            transition: all 560ms cubic-bezier(0.77, 0, 0.18, 1);
        }
        
        .cart-footer__action {
            cursor: pointer;
        }
        
        .cart-footer__indicator {
            margin-right: 18px;
            transition: transform 0.2s linear;
        }
        
        .cart-footer__indicator--active {
            transform: rotate(180deg);
            transition: transform 0.2s linear;
        }
        
        .cart-footer__total {
            display: block;
        }
        
        .cart-footer__payment {
            z-index: 2;
            background-color: #fff;
        }
        .pop-up-cart {
            width: 100vw;
            height: 0;
            opacity: 0;
            visibility: hidden;
            padding-bottom: 11rem;
            transition: height 560ms cubic-bezier(0, 0.01, 0.18, 1),
                        opacity 560ms cubic-bezier(0, 0.01, 0.18, 1),
                        visibility 560ms cubic-bezier(0, 0.01, 0.18, 1);
        }
       
        .pop-up-cart--visible {
            max-height: 100vh;
            height: 100%;
            overflow-y: auto;
            opacity: 1;
            visibility: visible;
            transition: height 560ms cubic-bezier(0, 0.01, 0.18, 1) 340ms,
                        visibility 560ms cubic-bezier(0, 0.01, 0.18, 1) 340ms;
        }
        .order-item {
            padding: 1rem 0 2rem;
            background-color: #fff;
            margin-bottom: 1rem;
            border-bottom: 1px solid #E4E7EC;
        
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        
        .order-item__footer {
            padding-top: 0.5rem;
            padding-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        .order-item__actions {
            display: flex;
            align-items: center;
        }
        
        .order-item__actions-group {
            display: flex;
            align-items: center;
        }
        
        .order-item__actions-svg {
            width: 1.25rem;
            height: 1.25rem;
        }
        
        .order-item__amount {
            margin: 0 8px;
            min-width: 2rem;
            text-align: center;
            display: inline-block;
        }
        .padding-1dot25rem-1rem {
            padding: 1.25rem 1rem;
        }
        .white-space-nowrap {
            white-space: nowrap;
        }
        .gap-0dot5rem-1rem {
            gap: 0.5rem 1rem;
        }
        .flex-order-1 {
            order: 1;
        }
        .flex-order-2 {
            order: 2;
        }
        
        @media only screen and (min-width: 768px) {
            .fcfc__accent {
                font-size: 2.4rem;
            }
            .mw768-display-block {
                display: block;
            }
            .mw768-display-none {
                display: none;
            }
            .pop-up-cart-backdrop.show {
                content: '';
                width: 100vw;
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                max-height: 100vh;
                height: 100%;
                background: rgba(52, 64, 84, 0.6);
                backdrop-filter: blur(8px);
                overflow: hidden;
            }
            .pop-up-cart {
                width: 27.5rem;
                right: -27.5rem;
                height: 100vh;
                overflow-y: auto;
                padding-bottom: 17.25rem;
                transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);

                .cart-footer {
                    width: 27.5rem;
                    right: -27.5rem;
                    opacity: 0;
                    visibility: hidden;
                    transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
                }
            }
            .pop-up-cart--visible {
                right: 0;
                transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);

                .cart-footer {
                    opacity: 1;
                    right: 0;
                    visibility: visible;
                    transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
                }
            }
            .mw768-padding-1dot25rem-2dot5rem {
                padding: 1.25rem 2.5rem;
            }
            .cart-footer {
                right: 0;
                left: auto;
                padding: 1.5rem;
            }
            .cart-footer__details {
                opacity: 1;
                visibility: visible;
                height: 36px;
                padding: 0 0 12px;
                transition: none;
            }
            .mw768-flex-order-1 {
                order: 1;
            }
            .mw768-flex-order-2 {
                order: 2;
            }
        }
    }
}