@import '../../../components/theme/ogle-colors.scss';

.ogle {
    .pages {
        .ticketing-success-pop-up {
            display: none;
            
            &.ticketing-success-pop-up--visible {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 10;
                width: 100vw;
                max-height: 100vh;
                height: 100%;
                overflow: hidden;

                &:after {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: rgba(52, 64, 84, 0.6);
                    backdrop-filter: blur(8px);
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .ticketing-success-pop-up__container {
                position: absolute;
                bottom: 0;
                background-color: #fff;
                margin: 0 auto;
                box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
                border-radius: 12px 12px 0px 0px;
                text-align: center;
                z-index: 11;
                width: 100%;
            }

            .ticketing-success-pop-up__title {
                padding: 0.75rem 1.5rem;
                background-color: map-get($colors, blue-gray-50);
                color: map-get($colors, gray-700);
                border-radius: 12px 12px 0px 0px;
                border-bottom: 1px solid map-get($colors, gray-100);
                text-align: start;
            }

            .ticketing-success-pop-up__content {
                padding: 0.75rem 1rem 1rem;
            }

            @media screen and (min-width: 768px) {
                .ticketing-success-pop-up__container {
                    border-radius: 12px;
                    top: 50%;
                    left: 50%;
                    bottom: auto;
                    transform: translate(-50%, -50%);
                    max-width: 25rem;
                    padding: 1.5rem;
                    width: 100%;
                }

                .ticketing-success-pop-up__title {
                    padding: 0;
                    background-color: initial;
                    color: map-get($colors, gray-900);
                    border-bottom: none;
                    text-align: center;
                }

                .ticketing-success-pop-up__content {
                    padding: 2rem 0 0;
                }
            }
        }
    }
}