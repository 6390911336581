@import '../../components/theme/ogle-colors.scss';

.error-message-container {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    width: 21.5rem;

    & + .error-message-container {
        margin-top: 1rem;
    }
}

.word-break-break-all {
    word-break: break-all;
}

.additional-message {
    font-size: 0.625rem
}

.checkout-shell-alert-icon {
    color: map-get($colors, error-25);;
    fill: map-get($colors, error-500);
}

.checkout-shell-info-icon {
    color: map-get($colors, white);
    fill: map-get($colors, primary-600);
    stroke: #ffffff;
    
    & path {
        stroke: #ffffff;
    }
}