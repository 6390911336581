@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.origin {
	font-family: 'Open Sans', sans-serif;
	line-height: 1.4; /* Open+Sans */

	ol, ul {
		list-style: none;
	}

	a {
		color: black;
		cursor: pointer;
		text-decoration: none;
	}

	@media only screen and (min-width: 768px) {
		a:hover {
			text-decoration: underline;
		}
	}

	a.focus {
		color: darkblue;
	}

	.textarea {
		display: block;
		width: 100%;
		border: 1px solid;
	}

	.inline {
		border-left: 1px dotted grey;
		padding-left: 1rem;
		overflow: auto;
	}

	.link {
		color: #00b9e4;
	}
	.cabinet-list-link {
		color: #005A9F;
	}

	.button {
		border: 1px solid;
		padding: 0.4rem 0.8rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		background: none;

		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.button.primary {
		font-weight: bold;
	}

	.button.button-no-flex {
		display: initial;
	}

	@media only screen and (min-width: 768px) {
		.button:hover {
			color: grey;
		}
	}

	.button.disabled {
		background-color: #E8E8E8;
		color: #808080;
	}

	.button-container {
		display: flex;
		justify-content: center;

		.button {
			margin-right: 15px;
		}
	}

	.form > div:not(:first-child) {
		margin-top: 1rem;
	}

	.input input::-webkit-outer-spin-button, .input input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.input input[type=number] {
		-moz-appearance: textfield;
	}

	.input input {
		padding: 0.5rem;
		display: block;
		border: 1px solid;
	}

	.select select {
		background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		-moz-border-radius: 0;
		-webkit-border-radius: 0;
		border-radius: 0;
		padding: 0.5rem 1.5rem 0.5rem 0.5rem;
		display: block;
		border: 1px solid;
	}

	.radio label, .checkbox label {
		display: flex;
		align-items: center;
	}

	.radio label input, .checkbox label input {
		margin-right: 0.6rem;
	}

	.input-error {
		color: indianred;
	}

	.input-warning {
		color: darkorange;
	}

	.input-label {
		font-weight: bold;
	}

	.input-label sup {
		color: indianred;
	}

	.border-bottom-1px-solid.lightgrey {
		border-color: lightgrey;
	}
	.border-bottom-1px-solid.grey {
		border-color: grey;
	}

	@media only screen and (min-width: 768px) {
		.view {
			max-width: 1000px;
			margin: 0 auto;
		}
	}
	.block {
		margin: 0 1rem;
	}

	.margin-left-4px {
		margin-left: 4px;
	}

	.divider {
		border-bottom: 1px solid;
		height: 1px;
	}

	.border-color-lightgrey {
		border-color: lightgrey;
	}

	.font-size-14px {
		font-size: 14px;
	}

	.flex-direction-adaptive {
		flex-direction: column;
	}

	@media only screen and (min-width: 768px) {
		.flex-direction-adaptive {
			flex-direction: row;
		}
	}

	.padding-left-20px {
		padding-left: 20px;
	}

	.padding-right-20px {
		padding-right: 20px;
	}

	.highlight {
		color: white;
	}

	.highlight.palevioletred {
		background-color: #db7093;
	}
	.highlight.lightslategrey {
		background-color: lightslategrey;
	}

	.badge {
		background-color: #FF8C00;
		color: white;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		display: inline-block;
	}

	.badge.palevioletred {
		background-color: #db7093;
	}

	.badge.orangered {
		background-color: orangered;
	}

	.badge.lightslategrey {
		background-color: lightslategrey;
	}

	.badge.steelblue {
		background-color: steelblue;
	}

	.badge.deeppink {
		background-color: deeppink;
	}

	.tag {
		border: 1px solid black;
		display: inline-block;
		border-radius: 2px;
		padding: 0.1rem 0.2rem;
	}

	.tag.tag-cyan {
		color: #08979c;
		background: #e6fffb;
		border-color: #87e8de;
	}

	.tag.tag-orange {
		color: #d46b08;
		background: #fff7e6;
		border-color: #ffd591;
	}

	.tag.tag-purple {
		color: #531dab;
		background: #f9f0ff;
		border-color: #d3adf7;
	}

	.tag.tag-gold {
		color: #d48806;
		background: #fffbe6;
		border-color: #ffe58f;
	}

	.tag.tag-green {
		color: #389e0d;
		background: #f6ffed;
		border-color: #b7eb8f;
	}

	.tag.tag-red {
		color: #cf1322;
		background: #fff1f0;
		border-color: #ffa39e;
	}

	.tag.tag-volcano {
		color: #d4380d;
		background: #fff2e8;
		border-color: #ffbb96;
	}

	.tag.tag-purple {
		color: #531dab;
		background: #f9f0ff;
		border-color: #d3adf7;
	}

	.tag.tag-magenta {
		color: #c41d7f;
		background: #fff0f6;
		border-color: #ffadd2;
	}

	.background-gold {
		background: #FFD700;
	}

	.background-blue {
		background: blue;
	}

	.background-black {
		background: black;
	}

	.background-grey {
		background: grey;
	}

	.background-darkslateblue {
		background: darkslateblue;
	}

	.max-width-500px {
		max-width: 500px;
	}

	.color-grey {
		color: grey;
	}

	.color-green {
		color: green;
	}

	.color-blue {
		color: blue;
	}

	.color-white {
		color: white;
	}

	.color-lightgrey {
		color: lightgrey;
	}

	.color-orange {
		color: orange;
	}

	.color-firebrick {
		color: firebrick;
	}

	.line-height-24px {
		line-height: 24px;
	}

	.line-height-40px {
		line-height: 40px;
	}

	.dot {
		height: 0.4rem;
		width: 0.4rem;
		background-color: grey;
		border-radius: 50%;
		display: inline-block;
	}

	.dot.red {
		background-color: red;
	}

	.dot.green {
		background-color: green;
	}

	.dot.orange {
		background-color: orange;
	}

	.card {
		padding: 1rem 0;
		flex-basis: 100%;
	}

	@media only screen and (min-width: 768px) {
		.card {
			padding: 1rem;
			flex-basis: 50%;
		}
		.card.four {
			flex-basis: 25%;
		}
	}

	.delayed-spinner {
		transition: 0s 0.5s;
	}
	.delayed-spinner.in {
		opacity: 0.6;
	}
	.delayed-spinner.out {
		opacity: 1;
	}

	.header-title {
		margin-bottom: 3rem;
	}
	.padding-bottom-2rem {
		padding-bottom: 2rem;
	}
}