@import '../../../components/theme/ogle-colors.scss';

.ogle {    
    .pages {
        .warp {
            .pop-up-cart {
                width: 100vw;
                height: 0;
                opacity: 0;
                visibility: hidden;
                padding-bottom: 11rem;
                transition: height 560ms cubic-bezier(0, 0.01, 0.18, 1),
                            opacity 560ms cubic-bezier(0, 0.01, 0.18, 1),
                            visibility 560ms cubic-bezier(0, 0.01, 0.18, 1);
            }
        
            .pop-up-cart--visible {
                max-height: 100vh;
                height: 100%;
                overflow-y: auto;
                opacity: 1;
                visibility: visible;
                transition: height 560ms cubic-bezier(0, 0.01, 0.18, 1) 340ms,
                            visibility 560ms cubic-bezier(0, 0.01, 0.18, 1) 340ms;
            }

            .padding-1dot25rem-1rem {
                padding: 1.25rem 1rem;
            }

            .btn--primary-gradient {
                path {
                    stroke: #ffffff;
                }
            }

            .cart-btn--disabled:disabled {
                background: map-get($colors, blue-gray-900);
                color: map-get($colors, blue-gray-800);
                border: none;

                path {
                    stroke: map-get($colors, blue-gray-800);
                }
            }
        
            @media only screen and (min-width: 768px) {
                .pop-up-cart-backdrop.show {
                    content: '';
                    width: 100vw;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    max-height: 100vh;
                    height: 100%;
                    background: rgba(52, 64, 84, 0.6);
                    backdrop-filter: blur(8px);
                    overflow: hidden;
                }
                .pop-up-cart {
                    width: 27.5rem;
                    right: -27.5rem;
                    height: 100vh;
                    overflow-y: auto;
                    padding-bottom: 17.25rem;
                    transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
        
                    .local-cart-footer {
                        width: 27.5rem;
                        right: -27.5rem;
                        opacity: 0;
                        visibility: hidden;
                        transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
                    }
                }
                .pop-up-cart--visible {
                    right: 0;
                    transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
        
                    .local-cart-footer {
                        opacity: 1;
                        right: 0;
                        visibility: visible;
                        transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
                    }
                }
                .mw768-padding-1dot25rem-2dot5rem {
                    padding: 1.25rem 2.5rem;
                }
            }
        }
    }
}