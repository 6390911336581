@font-face {
    font-family: e-Ukraine;
    src: url('../../../../../public/font/e-Ukraine_typeface/e-Ukraine/e-Ukraine-Regular.otf') format('opentype');
}

@font-face {
    font-family: e-Ukraine;
    font-weight: bold;
    src: url('../../../../../public/font/e-Ukraine_typeface/e-Ukraine/e-Ukraine-Bold.otf') format('opentype');
}

.top-projects-container {
    max-width: 100vw;
    overflow-x: auto;
    padding: 2rem 1.5rem 4rem;
    margin: 0 -1.5rem;
    width: 100vw;
}

.top-projects-block {
    margin-right: 1.5rem;
    flex-direction: row;

    &:last-of-type {
        margin-right: 0;
    }
}

.top-projects-item {
    height: 368px;
    width: 280px;
    border-radius: 12px;
    flex-grow: 1;
    box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.3), 0px 30px 60px -12px rgba(50, 50, 93, 0.25);

    &:last-of-type {
        margin-right: 0;
    }
}

.top-projects-item--arsenal {
    background-image: url(../../../../assets/arsenal-bg.jpeg);
    background-position-x: center;
    background-size: 700px;

    .top-projects-item__description {
        background-color: rgba(255, 255, 255, 0.3);
    }

}

.top-projects-item--e-aid {
    background: linear-gradient(112.76deg, #B3E6C1 1.98%, #7BD9C2 32.4%, #4D9FCD 100%), #FAFAFA;
    justify-content: space-between;
}

.top-projects-item--master-wash {
    background-image: url(../../../../assets/master-wash-bg.jpeg);
    background-position-x: -300px;
    background-size: 700px;
    background-position-y: -65px;

    .top-projects-item__description {
        background-color: rgba(18, 183, 106, 0.7);
    }
}

.top-projects-item__card {
    font-size: 1rem;
    line-height: 120%;
    flex-grow: 1;
    color: #000000;
    font-family: 'e-Ukraine', Inter;
    padding: 72px 2px 0 18px;
}

.top-projects-item__card-title {
    padding-top: 8px;
}

.top-projects-item__description {
    padding: 24px 28px 32px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    color: #ffffff;
    position: relative;
    backdrop-filter: blur(24px);
    width: 100%;
    align-self: flex-end;
    border-radius: 0 0 12px 12px;
    font-size: 1rem;
    line-height: 1;
    box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.3);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
    }
}

.top-projects-item__title {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    line-height: 1.5;
}

.top-projects-item__link {
    position: absolute;
    top: 28px;
    right: 20px;
    width: 1rem;
    height: 1rem;
}

.top-projects-item__link-img {
    color: #ffffff;
}

.top-projects-item--small {
    display: none;
}

// @media only screen and (min-width: 768px) and (max-width: 1023px) {
//     .top-projects-container {
//         left: -2rem;
//         padding: 0 2rem 2.5rem;
//     }
// }



@media only screen and (min-width: 1024px) {

    .top-projects-container {
        overflow: visible;
        width: initial;
        padding: 0.5rem;
        margin: 0;
    }

    .top-projects-block {
        margin-right: 2rem;
        flex-direction: column;
    }

    .top-projects-item {
        margin-right: 0;
        flex-grow: 0;
    }

    .top-projects-block + .top-projects-block {
        .top-projects-item {
            margin-right: 0;
        }
    }

    .top-projects-item--small + .top-projects-item {
        margin-top: 44px;
    }
}