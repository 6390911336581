.ogle {
    .coupon {
        .form {
            input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none;}
            input[type=number] {-moz-appearance: textfield;}
            input:focus {outline: none;}
    
            .input {
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
            .input:focus-within {
                border-color: #84CAFF;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #EFF8FF;
            }
            .input.errors, .input.errors:focus-within {
                border-color: #FDA29B;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEF3F2;
            }
        }
    }
}