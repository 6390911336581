@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body:has(.pop-up-cart--visible) {
    overflow-y: hidden;
}
.origin {
    .chuprynenko {
        background: black;
        color: #E4E7EC;
        min-height: 100vh;
        font-family: 'Inter', sans-serif;


        a {
            color: white;
            display: inline-block;
        }

        hr {
            border: none;
            color: #344054;
            background-color: #344054;
            height: 1px;
        }

        &__header {
            padding: 1rem 1.25rem;
        }

        &__header a {
            padding: 0.4rem 0;
            text-decoration: none;
        }

        &__accent {
            font-size: 1.6rem;
        }

        &__title {
            font-size: 2rem;
            text-align: center;
        }

        &__footer {
            padding: 1rem 0;
            font-size: 0.8rem;
        }

        &__footer-line {
            display: flex;
            align-items: center;
        }

        &__footer-line a {
            font-size: 1.4rem;
            padding: 0.4rem 0;
            font-weight: bold;
            text-decoration: none;
        }

        &__footer-line-left {
            flex: 1;
            border-bottom: 1px solid lightgrey;
            line-height: 0.1rem;
        }

        &__footer-line-right {
            flex: 1;
            border-bottom: 1px solid lightgrey;
            line-height: 0.1rem;
        }

        &__footer-line-left {
            margin-right: 1.5rem;
        }
        &__footer-line-right {
            margin-left: 1.5rem;
        }

        &__payment {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .9;
        }

        &__payment img {
            width: 300px;
            object-fit: contain;
        }

        &__footer-content a:not(:last-child) {
            padding-right: 1rem;
        }

        &__footer-rights {
            display: flex;
            justify-content: center;
        }

        &__footer-info {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        .marked {
            color: white;
        }
        .marked img {
            max-height: 100%;
        }
        .marked p {
            padding-bottom: 1rem;
        }
        .marked hr {
            margin-bottom: 1rem;
        }
        .marked strong {
            font-weight: bold;
        }

        .view a {
            color: #2E90FA;
        }

        .color-primary-500 {
            color: #2E90FA;

            path {
                stroke: #2E90FA;
            }
        }

        .button {
            border-radius: 0.5rem;
            padding: 0.625rem 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.25rem;
            &.primary {
                color: white;
                border-color: #D0D5DD;
            }
        }
        .top-1dot25rem {
            top: 1.25rem;
        }
        
        .right-1dot25rem {
            right: 1.25rem;
        }
        
        .height-2rem {
            height: 2rem;
        }

        .padding-top-2rem {
            padding-top: 2rem;
        }
        .border-color-gray-700 {
            border-color: #344054;
        }
        .margin-left-0dot75rem {
            margin-left: 0.75rem;
        }
        .text-lg {
            font-size: 1.125rem;
            line-height: 1.75rem;
        }
        .medium {
            font-weight: 500;
        }
        .min-width-11rem {
            min-width: 11rem;
        }
        .btn {
            cursor: pointer;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            font-weight: 500;
            position: relative;

            &:active {
                transform: scale(0.98);
            }
        
            &.btn--disabled, &:disabled {
                cursor: default;
                pointer-events: none;
            }
        }
        .btn--lg {
            padding: 0.625rem 1.125rem;
            font-size: 1rem;
            line-height: 1.5rem;
        
            svg, img {
                min-height: 1rem;
            }
        }
        .btn--xl {
            padding: 0.75rem 1.25rem;
            font-size: 1rem;
            line-height: 1.5rem;
        
            svg, img {
                height: 1rem;
            }
        }
        .btn--2xl {
            padding: 1rem 2.5rem;
            font-size: 1rem;
            line-height: 1.5rem;
        
            svg, img {
                height: 1rem;
            }
        }
        .btn--primary-gradient {
            color: #ffffff;
            background: linear-gradient(90deg, #1570EF 0%, #2E90FA 100%);
            path {
                stroke: #ffffff;
            }
            &a {
                color: #ffffff;
            }
        
            &:hover {
                background: linear-gradient(90deg, #175CD3 0%, #1570EF 100%);
            }
        
            &:active {
                background: linear-gradient(90deg, #175CD3 0%, #1570EF 100%);
            }
            &.btn--disabled {
                background: #101323;
                color: #293056;
                border:none;

                path {
                    stroke: #293056;
                }
            }
        }
        .btn--fluid {
            flex: 1 0;
            display: flex;
            width: 100%;
        }
        .color-gray-100 {
            color: #F2F4F7;
        }
        .display-none {
            display: none;
        }
        .background-color-white {
            background-color: #ffffff;
        }
        .bottom-0 {
            bottom: 0;
        }
        .left-0 {
            left: 0;
        }
        .width-100percent {
            width: 100%;
        }
        .text-xl {font-size: 1.25rem; line-height: 1.875rem;}
        .text-lg {font-size: 1.125rem; line-height: 1.75rem;}
        .text-md {font-size: 1rem; line-height: 1.5rem;}
        .text-sm {font-size: 0.875rem; line-height: 1.25rem;}
        .display-sm {font-size: 1.875rem; line-height: 2.375rem;}
        .color-gray-700 {
            color: #344054;
        }
        .color-gray-900 {
            color: #101828;
        }
        .background-color-black {
            background-color: #000000;
        }
        .position-sticky {
            position: sticky;
        }
        .top-0 {
            top: 0;
        }
        .cart-footer {
            background-color: #fff;
            box-shadow: 0px -24px 48px -12px rgba(16, 24, 40, 0.05);
            border-radius: 12px 12px 0px 0px;
            padding: 1.25rem;
            width: 100vw;
        
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .cart-footer__details {
            opacity: 0;
            visibility: hidden;
            height: 0;
            transition: all 560ms cubic-bezier(0.77, 0, 0.18, 1);
            padding-left: 2.375rem;
        }
        
        .cart-footer__details--visible {
            opacity: 1;
            visibility: visible;
            height: 36px;
            padding-bottom: 12px;
            transition: all 560ms cubic-bezier(0.77, 0, 0.18, 1);
        }
        
        .cart-footer__action {
            cursor: pointer;
        }
        
        .cart-footer__indicator {
            width: 10px;
            margin-right: 18px;
            transition: transform 0.2s linear;
        }
        
        .cart-footer__indicator--active {
            transform: rotate(180deg);
            transition: transform 0.2s linear;
        }
        
        .cart-footer__total {
            display: block;
        }
        
        .cart-footer__payment {
            z-index: 2;
            background-color: #fff;
        }
        .z-index-1 {
            z-index: 1;
        }
        .color-gray-500 {
            color: #667085;

            path {
                stroke: #667085;
            }
        }
        .height-1dot25rem {
            height: 1.25rem;
        }
        .width-1dot25rem {
            width: 1.25rem;
        }
        .btn-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            height: 24px;
            width: 24px;
        }
        
        .btn-icon--primary {
            color: #2E90FA;
        
            &:hover {
                color: #1570EF;
            }
        
            &:active {
                color: #175CD3;
            }
        }
        
        .btn-icon--disabled {
            cursor: default;
            pointer-events: none;
            color: #E4E7EC;
        }
        .pop-up-cart {
            width: 100vw;
            height: 0;
            opacity: 0;
            visibility: hidden;
            padding-bottom: 11rem;
            transition: height 560ms cubic-bezier(0, 0.01, 0.18, 1),
                        opacity 560ms cubic-bezier(0, 0.01, 0.18, 1),
                        visibility 560ms cubic-bezier(0, 0.01, 0.18, 1);
        }
       
        .pop-up-cart--visible {
            max-height: 100vh;
            height: 100%;
            overflow-y: auto;
            opacity: 1;
            visibility: visible;
            transition: height 560ms cubic-bezier(0, 0.01, 0.18, 1) 340ms,
                        visibility 560ms cubic-bezier(0, 0.01, 0.18, 1) 340ms;
        }
        .right-0 {
            right: 0;
        }
        .color-primary-600 {
            color: #1570EF;

            path {
                stroke: #1570EF;
            }
        }
        .order-item {
            padding: 1rem 0 2rem;
            background-color: #fff;
            margin-bottom: 1rem;
            border-bottom: 1px solid #E4E7EC;
        
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        
        .order-item__footer {
            padding-top: 0.5rem;
            padding-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        .order-item__actions {
            display: flex;
            align-items: center;
        }
        
        .order-item__actions-group {
            display: flex;
            align-items: center;
        }
        
        .order-item__actions-svg {
            width: 1.25rem;
            height: 1.25rem;
        }
        
        .order-item__amount {
            margin: 0 8px;
            min-width: 2rem;
            text-align: center;
            display: inline-block;
        }
        .padding-1dot25rem-1rem {
            padding: 1.25rem 1rem;
        }
        .padding-left-1dot25rem {
            padding-left: 1.25rem;
        }
        .padding-right-1dot25rem {
            padding-right: 1.25rem;
        }
        .padding-bottom-0dot75rem {
            padding-bottom: 0.75rem;
        }
        .semibold {
            font-weight: 600;
        }
        .margin-right-0dot75rem {
            margin-right: 0.75rem;
        }
        .white-space-nowrap {
            white-space: nowrap;
        }
        .padding-top-0dot25rem {
            padding-top: 0.25rem;
        }
        .padding-top-0dot1875rem {
            padding-top: 0.1875rem;
        }
    }

    @media only screen and (max-width: 767px) {
        .hide-mobile {
            display: none;
        }
    }
    
    @media only screen and (min-width: 768px) {
        .chuprynenko {
            .mw768-height-2dot5rem {
                height: 2.5rem;
            }

            &__accent {
                font-size: 2.4rem;
            }
            .mw768-display-block {
                display: block;
            }
            .mw768-display-none {
                display: none;
            }
            .pop-up-cart-backdrop.show {
                content: '';
                width: 100vw;
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                max-height: 100vh;
                height: 100%;
                background: rgba(52, 64, 84, 0.6);
                backdrop-filter: blur(8px);
                overflow: hidden;
            }
            .pop-up-cart {
                width: 27.5rem;
                right: -27.5rem;
                height: 100vh;
                overflow-y: auto;
                padding-bottom: 17.25rem;
                transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);

                .cart-footer {
                    width: 27.5rem;
                    right: -27.5rem;
                    opacity: 0;
                    visibility: hidden;
                    transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
                }
            }
            .pop-up-cart--visible {
                right: 0;
                transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);

                .cart-footer {
                    opacity: 1;
                    right: 0;
                    visibility: visible;
                    transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1), opacity 560ms cubic-bezier(0.77, 0, 0.18, 1), visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
                }
            }
            .mw768-padding-1dot25rem-2dot5rem {
                padding: 1.25rem 2.5rem;
            }
            .mw768-padding-left-2dot5rem {
                padding-left: 2.5rem;
            }
            .mw768-padding-right-2dot5rem {
                padding-right: 2.5rem;
            }
            .mw768-text-mlg {
                font-size: 1.125rem;
                line-height: 1.75rem;
            }
            .mw768-text-md {
                font-size: 1rem;
                line-height: 1.5rem;
            }
            .cart-footer {
                right: 0;
                left: auto;
                padding: 1.5rem;
            }
            .cart-footer__details {
                opacity: 1;
                visibility: visible;
                height: 36px;
                padding: 0 0 12px;
                transition: none;
            }
        }
    }
}